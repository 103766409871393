import Vue from 'vue';
import Router from 'vue-router';

// 避免加载重复页面时报错
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

Vue.use(Router);

const routes = [
  // {
  //   path: '*',
  //   redirect: '/home'
  // },
  {
    // name: '/',
    path: '/',
    component: () => import('./view/index.vue'),
    meta: {
      // title: '【深圳家具展】深圳家居设计周暨深圳国际家具展 - 官方网站',
    }
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/index.vue'),
    meta: {
      // title: '【深圳家具展】深圳家居设计周暨深圳国际家具展 - 官方网站',
    }
  },
  {
    name: 'index',
    path: '/index',
    component: () => import('./view/index.vue'),
    meta: {
      // title: '【深圳家具展】深圳家居设计周暨深圳国际家具展 - 官方网站',
    }
  },
  {
    name: 'intro',
    path: '/intro',
    component: () => import('./view/intro.vue'),
    meta: {
      // title: '展会介绍',
    }
  },
  {
    name: 'theme',
    path: '/theme',
    component: () => import('./view/theme.vue'),
    meta: {
      // title: '主题馆',
    }
  },
  {
    name: 'exhibitor',
    path: '/exhibitor',
    component: () => import('./view/exhibitor.vue'),
    meta: {
      // title: '展商名录',
    }
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('./view/register.vue'),
    meta: {
      // title: '预先登记',
    }
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import('./view/contact.vue'),
    meta: {
      // title: '联系我们',
    }
  },
  {
    name: 'service',
    path: '/service',
    component: () => import('./view/service.vue'),
    meta: {
      // title: '展商服务',
    }
  },
  {
    name: 'traffic',
    path: '/traffic',
    component: () => import('./view/traffic.vue'),
    meta: {
      // title: '交通商旅',
    }
  },
  {
    name: 'guide',
    path: '/guide',
    component: () => import('./view/guide.vue'),
    meta: {
      // title: '展会导览图',
    }
  },
  {
    name: 'property',
    path: '/property',
    component: () => import('./view/property.vue'),
    meta: {
      // title: '知产保护',
    }
  },
  {
    name: 'expo',
    path: '/expo',
    component: () => import('./view/expo.vue'),
    meta: {
      // title: '展会资讯',
    }
  },
  {
    name: 'news',
    path: '/news',
    component: () => import('./view/news.vue'),
    meta: {
      // title: '品牌资讯',
    }
  },
  {
    name: 'smart-news',
    path: '/smart-news',
    component: () => import('./view/smart-news.vue'),
    meta: {
      // title: '智能家居资讯',
    }
  },
  {
    name: 'activity',
    path: '/activity',
    component: () => import('./view/activity.vue'),
    meta: {
      // title: '展会活动',
    }
  },
  // {
  //   name: 'activityDetail',
  //   path: '/activityDetail',
  //   component: () => import('./view/activityDetail.vue'),
  //   meta: {
  //     title: '展会活动'
  //   }
  // },
  {
    name: 'history',
    path: '/history',
    component: () => import('./view/history.vue'),
    meta: {
      // title: '往届概述',
    }
  },
  {
    name: 'bbs',
    path: '/bbs',
    component: () => import('./view/bbs.vue'),
    meta: {
      // title: '高峰论坛',
    }
  },
  // {
  //   name: 'bbsDetail',
  //   path: '/bbsDetail',
  //   component: () => import('./view/bbsDetail.vue'),
  //   meta: {
  //     title: '论坛'
  //   }
  // },
  {
    name: 'forum',
    path: '/forum',
    component: () => import('./view/forum.vue'),
    meta: {
      // title: '论坛',
    }
  },
  {
    name: 'newsDetail',
    path: '/newsDetail',
    component: () => import('./view/newsDetail.vue'),
    meta: {
      // title: '资讯',
    }
  },
  {
    name: 'media',
    path: '/media',
    component: () => import('./view/media.vue'),
    meta: {
      // title: '媒体中心',
    }
  },
  {
    name: 'picture',
    path: '/picture',
    component: () => import('./view/picture.vue'),
    meta: {
      // title: '视频&图片',
    }
  },
  {
    name: 'library',
    path: '/library',
    component: () => import('./view/library.vue'),
    meta: {
      // title: '精彩资源',
    }
  },
  {
    name: 'guest',
    path: '/guest',
    component: () => import('./view/guest.vue'),
    meta: {
      // title: '论坛嘉宾',
    }
  },
  {
    name: 'competition',
    path: '/competition',
    component: () => import('./view/competition.vue'),
    meta: {
      // title: '设计大赛',
    }
  },
  {
    name: 'smart',
    path: '/smart',
    component: () => import('./view/smart.vue'),
    meta: {
      title: '【智能家居】深圳智能家居博览会 ',
    }
  },
  {
    name: 'search',
    path: '/search',
    component: () => import('./view/search.vue'),
    meta: {
      // title: '搜索',
    }
  },
];


const router = new Router({
  mode: 'history', // SEO: prerender-spa-plugin 预渲染时必须使用 history 模式
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 } // 滚动到位置的坐标
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '【深圳家具展】深圳家居设计周暨深圳国际家具展 - 官方网站';
  next();
});

export {
  router
};
